import Api from "utils/api";

export const fetchAppInstance = async ({ queryKey }: any) => {
  const [, { instanceSlug }] = queryKey;
  return Api.get(`/accounts/slug/${instanceSlug}/`, {
    headers: {
      noBearerToken: true,
    },
  }).then((res) => res.data);
};
