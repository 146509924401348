import React, { useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { AdminPaths, AuthPaths } from "routes/paths";
import { getToken, logOut } from "utils/auth";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  useMediaQuery,
  Paper,
  Popover,
  DialogTitle,
  Dialog,
  IconButton,
  DialogContent,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import { drawerConfigSettings, LayoutContext } from "./contexts/LayoutContext";
import AdminSidebar from "./Sidebars/AdminSidebar";
import { getDecodedJwt } from "utils/auth";
import { IDecodedUser } from "authModule/interfaces/authInterfaces";
import { ReactComponent as NotificationIcon } from "assets/svgs/notification.svg";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import logo from "assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { ILayoutProps } from "shared/interfaces/layoutProps";
import { useAppInstance } from "contexts/AppInstanceProvider";
import NotificationDrawer from "shared/components/Notification/NotificationDrawer";
import { ReactComponent as Hover } from "assets/svgs/hover.svg";
import { stepConfigType } from "../layouts/contexts/LayoutContext";
import { ReactComponent as Check } from "assets/svgs/check.svg";
import { getFullnameInitial } from "utils/transform";
// import { ReactComponent as ProfileIcon } from "assets/svgs/Profile.svg";
// import { ReactComponent as LogoutIcon } from "assets/svgs/logoutIcon.svg";
import {
  Close,
  Download,
  EmailOutlined,
  MessageOutlined,
  PhoneOutlined,
  PlaceOutlined,
} from "@mui/icons-material";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { getContactInfo } from "pages/Settings/services/contactInformation";
import { useSocket } from "hooks/useSocket";
import { workbenchSocketUrl } from "utils/api";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { fetchNotifications } from "shared/components/Notification/NotificationServices";

const drawerWidth = 232;
const DEFAULT_MOBILE_DRAWER_WIDTH = 80;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: "56px !important",
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type INotificationData = {
  created_at: string;
  id: string;
  link: string;
  message: string;
  owner: string;
  previous_action: null;
  read: boolean;
  request_id: string;
  request_type: string;
  state: string;
  status_flag: string;
  title: string;
  updated_at: string;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  boxShadow: "inset 0px -1px 0px #E5E5EA",
}));

export default function MainLayout({ children, sidebarLinks, isSuperAdmin }: ILayoutProps) {
  const instanceData = useAppInstance();
  const decodedUser: IDecodedUser = getDecodedJwt();
  const { userDetails } = useAuthenticatedUser();
  const theme = useTheme();
  const [showSidbar, setShowSidebar] = useState(true);
  const [isChangeLayoutBg, setIsChangeLayoutBg] = useState(false);
  const [removePadding, setRemovePadding] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showPrevText, setShowPrevText] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const queryClient = useQueryClient();

  const [drawerConfig, setDrawerConfig] = useState<drawerConfigSettings>({
    isMobile: true,
    drawerWidth: DEFAULT_MOBILE_DRAWER_WIDTH,
  });
  const [sideStepsConfig, setSideStepsConfig] = useState<Partial<stepConfigType[]>>();
  const mobile = useMediaQuery(theme.breakpoints.down("sm")) && !drawerConfig?.isMobile;
  const stackMenu = !!drawerConfig?.isMobile;
  const navigate = useNavigate();

  useInfiniteQuery<any, Error>(
    ["fetching-user-notification", { page: 1, pageSize: 10 }],
    fetchNotifications,
    {
      getNextPageParam: (lastPage: any, pages: any) => {
        const pagesNum = lastPage?.total_pages;
        if (pagesNum > pages?.length) {
          const newPage = pages?.length + 1;
          return newPage;
        }
        return undefined;
      },
      onSuccess: (data: any) => {
        const { pages } = data;
        const notificationList = pages.flatMap((x: any) => x.results);
        setNotificationData(notificationList);
      },
    }
  );

  const getUnreadNotificationCount = useMemo(() => {
    const value = notificationData.filter((val: INotificationData) => val.read === false);
    return value.length;
  }, [notificationData]);

  const showPreview = () => {
    return (
      <Box marginTop={"90px"} onClick={() => setOpenProgress(true)}>
        <Hover />
        <Typography
          sx={{
            transform: "rotate(-90deg)",
            whiteSpace: "nowrap",
          }}
          color="textSecondary"
          pt={6}
        >
          View Progress
        </Typography>
      </Box>
    );
  };
  const openFileUrlInNewWindow = (url: string) => {
    window.open(url, "_blank");
  };

  const { data: contactData, isLoading: isContactLoading } = useQuery(
    ["contact-information"],
    getContactInfo
  );
  const socketUrl = `${workbenchSocketUrl}?token=${getToken()}`;

  const { notifications } = useSocket({ url: socketUrl });

  const renderFancyDrawerSteps = () => {
    return (
      <>
        {showPrevText ? (
          showPreview()
        ) : (
          <Box mr={5} mt={5}>
            {sideStepsConfig?.map((step, i) => {
              return (
                <Box
                  key={i}
                  my={3}
                  width="30px"
                  height="30px"
                  borderRadius={"100%"}
                  bgcolor={
                    step!?.status === "done"
                      ? "#5ACA75"
                      : step!?.status === "current"
                      ? "#0050C8"
                      : "#A7A9BC"
                  }
                  display="grid"
                  sx={{ placeItems: "center" }}
                >
                  <Typography sx={{ color: "#fff" }}>
                    {step!?.status === "done" ? <Check /> : step?.label}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </>
    );
  };
  const handleOpen = () => {
    setOpenDrawer(!openDrawer);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };

  const handleCloseModal = () => {
    setAnchorEl(false);
  };

  const id = anchorEl ? "simple-popover" : undefined;

  const removedPaddingStyle = useMemo(() => removePadding, [removePadding]);
  const memoizedShowSidebar = useMemo(() => showSidbar, [showSidbar]);
  const isChangeLayoutBgStyle = useMemo(() => isChangeLayoutBg, [isChangeLayoutBg]);

  return (
    <Box display="flex" flexWrap="wrap" position="relative">
      <CssBaseline />
      <AppBar position="fixed" open={!mobile} elevation={0}>
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            component="div"
            sx={{
              width: "100%",
            }}
          >
            <Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
              <Avatar
                onClick={() => {}}
                src={instanceData?.logo || logo}
                sx={{
                  background: theme.palette.grey[400],
                  color: theme.palette.text.primary,
                  width: 30,
                  height: 30,
                  marginRight: 2,
                }}
              ></Avatar>
              <Typography color="primary" variant="subtitle1">
                {instanceData?.name}
              </Typography>
            </Box>
            <Box component="div" display="flex" alignItems="center" justifyContent="flex-end">
              {contactData?.data?.display_help_button && (
                <Box
                  onClick={() => setShowDialog(!showDialog)}
                  sx={{
                    width: "105px",
                    height: "32px",
                    border: "1px solid #0050C8",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    mr: 5,
                  }}
                >
                  <Typography color="primary" variant="body2">
                    Need Help?
                  </Typography>
                </Box>
              )}
              <Box
                onClick={() => handleOpen()}
                sx={{
                  display: "flex",
                  alignitems: "center",
                  justifyContent: "center",
                  mr: 3,
                  cursor: "pointer",
                  p: 3,
                  height: 40,
                  width: 40,
                  borderRadius: "100%",
                  border: "1px solid #E7E7ED",
                }}
                position="relative"
              >
                <NotificationIcon />
                <Box
                  position="absolute"
                  top={-6}
                  width="20px"
                  height="20px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  right={0}
                  p={2}
                  sx={{
                    backgroundColor: "#DA1414",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="caption" color="#fff">
                    {getUnreadNotificationCount > 10 ? (
                      <Typography variant="caption" color="#fff">
                        10+
                      </Typography>
                    ) : (
                      getUnreadNotificationCount
                    )}
                  </Typography>
                </Box>
              </Box>
              <Avatar
                onClick={handleClick}
                sx={{
                  background: decodedUser?.color_variants?.color_2 || theme.palette.primary.main,
                  color: theme.palette.common.white,
                  cursor: "pointer",
                }}
                aria-describedby={id}
                // onClick={handleClick}
              >
                {decodedUser?.image ? (
                  <img
                    src={decodedUser?.image}
                    alt="Profile"
                    style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  />
                ) : (
                  <Typography variant="h5" color={"#FFF"}>
                    {getFullnameInitial(decodedUser?.fullname) || "--"}
                  </Typography>
                )}
              </Avatar>
              <Box>
                <Popover
                  id={id}
                  open={anchorEl}
                  onClose={handleCloseModal}
                  anchorOrigin={{
                    vertical: 55,
                    horizontal: "right",
                  }}
                >
                  <Paper
                    sx={{
                      width: "304px",
                      // height: "94px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      pl: 4,
                      my: 1,
                    }}
                    elevation={0}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        my: 2,
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 70,
                          height: 70,
                          backgroundColor: theme.palette.primary.main,
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        {decodedUser?.image ? (
                          <img
                            src={decodedUser?.image}
                            alt="Profile"
                            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                          />
                        ) : (
                          <Typography variant="h5" color={"#FFF"}>
                            {getFullnameInitial(decodedUser?.fullname) || "--"}
                          </Typography>
                        )}
                      </Avatar>
                      <Box
                        sx={{
                          ml: 1.5,
                        }}
                      >
                        <Typography variant="subtitle1">{decodedUser?.fullname}</Typography>
                        <Typography variant="caption">{decodedUser?.email}</Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
                      <Typography variant="caption">Designation</Typography>
                      <Typography variant="caption" style={{ marginRight: "15px" }}>
                        {decodedUser?.designation || "N/A"}
                      </Typography>{" "}
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        cursor: "pointer",
                        pl: 1,
                        height: "28px",
                        my: 0.5,
                        "&:hover": {
                          backgroundColor: "#F1F2F6",
                          width: "90%",
                          borderRadius: "3px",
                          py: 0.5,
                        },
                      }}
                      onClick={() => {
                        navigate(AdminPaths.PROFILE, { replace: true });
                      }}
                    >
                      {" "}
                      <VisibilityOutlinedIcon />
                      <Typography sx={{ mx: 2 }} variant="body2">
                        View Profile
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        cursor: "pointer",
                        height: "28px",
                        my: 0.5,
                        pl: 1,
                        "&:hover": {
                          backgroundColor: "#F1F2F6",
                          width: "90%",
                          borderRadius: "3px",
                          py: 0.5,
                        },
                      }}
                      onClick={() => {
                        queryClient.invalidateQueries("appInstance");
                        logOut(() => navigate(AuthPaths.SIGNIN, { replace: true }));
                      }}
                    >
                      {" "}
                      <PowerSettingsNewOutlinedIcon style={{ color: "red" }} />{" "}
                      <Typography sx={{ mx: 2 }} variant="body2">
                        Log out
                      </Typography>
                    </Box>
                  </Paper>
                </Popover>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {memoizedShowSidebar && (
        <AdminSidebar
          stackMenu={stackMenu}
          links={sidebarLinks}
          width={drawerConfig?.drawerWidth || drawerWidth}
          mobile={mobile}
          superAdmin={isSuperAdmin}
          setDrawerConfig={setDrawerConfig}
          drawerConfig={drawerConfig}
          userRoles={userDetails?.roles}
          modules={userDetails?.modules}
        />
      )}
      <LayoutContext.Provider
        value={{
          setShowSidebar,
          setIsChangeLayoutBg,
          setRemovePadding,
          user: decodedUser,
          setSideStepsConfig,
          setDrawerConfig,
          openProgress,
          setOpenProgress,
          drawerWidth: drawerConfig?.drawerWidth || drawerWidth,
        }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            // pt: isChangeLayoutBgStyle ? 0 : 5,
            px: removedPaddingStyle || isChangeLayoutBgStyle ? 0 : 6,
            backgroundColor: isChangeLayoutBgStyle ? "#F5F7FA" : theme.palette.common.white,
            minHeight: "100vh",
            py: "32px",
            position: "relative",
            width: `calc(100% - ${drawerWidth}px)`,
          }}
        >
          <DrawerHeader sx={{ maxHeight: 48 }} />
          {children}
          {!!sideStepsConfig?.length ? (
            <Box
              onMouseLeave={() => setShowPrevText(false)}
              onMouseEnter={() => setShowPrevText(true)}
              position="fixed"
              top="calc(50% - 200px/2 + 0.5px)"
              right="0"
              width="60px"
              px={4}
              height="200px"
              bgcolor=" #F5F7FA"
              sx={{
                border: "1px solid #E5E5EA",
                boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.1)",
                borderRadius: "32px 0px 0px 32px",
              }}
            >
              {renderFancyDrawerSteps()}
            </Box>
          ) : null}
          <NotificationDrawer open={openDrawer} close={handleOpen} notifications={notifications} />
        </Box>
      </LayoutContext.Provider>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: 600,
            maxHeight: 700,
          },
        }}
        onClose={() => {
          setShowDialog(false);
        }}
        //   TransitionProps={{ onEntering: handleEntering }}
        open={showDialog}
        scroll="paper"
      >
        {/* @ts-ignore */}
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F1F2F6",
            height: "50px",
          }}
        >
          <Typography variant="h5">Help Center</Typography>
          <IconButton aria-label="close" onClick={() => setShowDialog(false)} size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 5 }}>
          {isContactLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {contactData?.data?.display_media_attachment && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ mb: 3 }}
                  onClick={() => openFileUrlInNewWindow(contactData?.data?.mda_manual)}
                >
                  <Box display="flex" alignItems="center">
                    <Download sx={{ fontSize: "14px", mr: 3 }} />{" "}
                    <Typography variant="caption" color="primary">
                      Download {contactData?.data?.document_title}
                    </Typography>
                  </Box>
                </Button>
              )}
              {contactData?.data?.display_contact_email && (
                <>
                  <Box sx={{ display: "flex", pt: 4, pb: 4 }}>
                    <Box display="flex" width="40%" alignItems="center" maxHeight="20px">
                      <Box
                        sx={{
                          backgroundColor: "#F1F2F6",
                          width: "15px",
                          height: "15px",
                          mr: 3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <EmailOutlined sx={{ color: "black", fontSize: "70%" }} />
                      </Box>
                      <Typography>Email Address</Typography>
                    </Box>
                    <Box width="60%">
                      <Typography variant="h6"> {contactData?.data?.contact_email}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
              {contactData?.data?.display_contact_person && (
                <>
                  <Box sx={{ display: "flex", pt: 4, pb: 4 }}>
                    <Box display="flex" width="40%" alignItems="center" maxHeight="20px">
                      <Box
                        sx={{
                          backgroundColor: "#F1F2F6",
                          width: "15px",
                          height: "15px",
                          mr: 3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MessageOutlined sx={{ color: "black", fontSize: "70%" }} />
                      </Box>
                      <Typography>Contact Person</Typography>
                    </Box>
                    <Box width="60%">
                      <Typography variant="h6"> {contactData?.data?.contact_person}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
              {contactData?.data?.display_phone && (
                <>
                  <Box sx={{ display: "flex", pt: 4, pb: 4 }}>
                    <Box display="flex" width="40%" alignItems="center" maxHeight="20px">
                      <Box
                        sx={{
                          backgroundColor: "#F1F2F6",
                          width: "15px",
                          height: "15px",
                          mr: 3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PhoneOutlined sx={{ color: "black", fontSize: "70%" }} />
                      </Box>
                      <Typography>Phone Number</Typography>
                    </Box>
                    <Box width="60%">
                      <Typography variant="h6"> {contactData?.data?.phone}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
              {contactData?.data?.display_address && (
                <>
                  <Box sx={{ display: "flex", pt: 4, pb: 4 }}>
                    <Box display="flex" width="40%" alignItems="center" maxHeight="20px">
                      <Box
                        sx={{
                          backgroundColor: "#F1F2F6",
                          width: "15px",
                          height: "15px",
                          mr: 3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PlaceOutlined sx={{ color: "black", fontSize: "70%" }} />
                      </Box>
                      <Typography>Office Address</Typography>
                    </Box>
                    <Box width="60%">
                      <Typography variant="h6">{contactData?.data?.office_name}</Typography>
                      <Typography variant="h6">{contactData?.data?.address1}</Typography>
                      <Typography variant="h6">{contactData?.data?.address2}</Typography>
                      <Typography variant="h6">
                        {contactData?.data?.city} {contactData?.data?.lga}{" "}
                        {contactData?.data?.contact_address}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
