const queryParamsHelper = (queryParams: any) => {
  let queries = "?";

  for (let [key, value] of Object.entries(queryParams || {})) {
    if (value && value !== undefined && value !== null && value instanceof Array) {
      //@ts-ignore
      for (let i = 0; i < value.length; i++) {
        //@ts-ignore
        queries += `${key}=${value[i]}&`;
      }
    } else {
      if (value && value !== undefined && value !== null) {
        queries += `${key}=${value}&`;
      }
    }
  }
  return queries?.substring(0, queries?.length - 1);
};

export const extractValueFromFilters = (filterArray: any[], label: string) => {
  const checkedVal = filterArray?.find((fa) => fa?.name === label)?.checkedValue;
  if (checkedVal === "All") return "";
  return checkedVal;
};

export default queryParamsHelper;
