import { createTheme } from "@mui/material/styles";
// import Card from './overrides/Card';
// import Input from './overrides/Input';
import palette from "./palette";
import typography, { responsiveFontSizes } from "./typography";
import { getDecodedJwt } from "utils/auth";
import { IDecodedUser } from "modules/Auth/interfaces/authInterfaces";

// NB: This is only extending/customizing the material ui theme
// refer to https://material-ui.com/customization/default-theme/ to see the default theme/props

declare module "@mui/material/styles" {
  interface Theme {
    customColors: {
      bg: string;
      bgLighter: string;
      darkSecondary: string;
    };
    primary: {
      border: string;
    };
    background: {
      primaryGrey: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customColors?: {
      bg?: string;
      bgLighter: string;
      darkSecondary: string;
    };
    primary?: {
      border?: string;
    };
    background?: {
      primaryGrey?: string;
    };
  }

  // interface PaletteOptions {
  //   background: {
  //     tableHeader: string;
  //     generalLight: string;
  //     success: string;
  //     disabled: string;
  //     blueShadeLight: string;
  //   };
  // }
}
const decodedUser: IDecodedUser = getDecodedJwt();

let themeInstance = createTheme({});

themeInstance = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        "#nprogress": {
          pointerEvents: "none",
        },
        "#nprogress .bar": {
          backgroundColor: decodedUser?.color_variants?.color_8,
          //  palette.primary.main,
          height: 3,
          left: 0,
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 2000,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 12,
          letterSpacing: 0,
          height: 32,
          width: 32,
        },
      },
    },
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          boxShadow: 0,
          border: 0,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeaderDraggableContainer:focus-visible, & .MuiDataGrid-columnHeaderDraggableContainer:focus-within":
            {
              outline: "none !important",
            },
          "& .MuiDataGrid-columnSeparator": {
            opacity: "0 !important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,

          // "& .MuiOutlinedInput-input": {
          //   padding: "12.5px 14px",
          //   height: "1.2em",
          // },

          "& .Mui-disabled": {
            backgroundColor: "#F1F2F6",
          },
        },
        // sizeSmall: {
        //   height: "40px",
        // },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#393A4A",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: "capitalize",
        },
        outlinedPrimary: {
          border: "0px",
          color: "#0050C8",
          background: "#F0F5FF",
          "&:hover": {
            border: "none",
            background: "rgba(0,80,200, 0.4)",
          },
        },
      },
    },
  },
  customColors: {
    bg: decodedUser?.color_variants?.color_2 || "#F8F7FA",
    bgLighter: decodedUser?.color_variants?.color_8 || "#E5E5EA",
    darkSecondary: decodedUser?.color_variants?.color_1 || "#605E5C",
  },
  palette,
  typography,
  spacing: (factor: number) => `${0.25 * factor}rem`,
});

themeInstance.typography.h1 = {
  ...themeInstance.typography.h1,
  ...responsiveFontSizes({
    lg: 38,
    md: 34,
    sm: 30,
    xs: 26,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.h2 = {
  ...themeInstance.typography.h2,
  ...responsiveFontSizes({
    lg: 30,
    md: 26,
    sm: 22,
    xs: 20,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.h3 = {
  ...themeInstance.typography.h3,
  ...responsiveFontSizes({
    lg: 23,
    md: 21,
    sm: 19,
    xs: 17,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.h4 = {
  ...themeInstance.typography.h4,
  ...responsiveFontSizes({
    lg: 20,
    md: 18,
    sm: 16,
    xs: 14,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.h5 = {
  ...themeInstance.typography.h5,
  ...responsiveFontSizes({
    lg: 18,
    md: 16,
    sm: 14,
    xs: 12,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.h6 = {
  ...themeInstance.typography.h6,
  ...responsiveFontSizes({
    lg: 14,
    md: 12,
    sm: 10,
    xs: 8,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.subtitle1 = {
  ...themeInstance.typography.subtitle1,
  ...responsiveFontSizes({
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.subtitle2 = {
  ...themeInstance.typography.subtitle2,
  ...responsiveFontSizes({
    lg: 14,
    md: 13,
    sm: 12,
    xs: 10,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.body1 = {
  ...themeInstance.typography.body1,
  ...responsiveFontSizes({
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.body2 = {
  ...themeInstance.typography.body2,
  ...responsiveFontSizes({
    lg: 14,
    md: 13,
    sm: 12,
    xs: 10,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.caption = {
  ...themeInstance.typography.caption,
  ...responsiveFontSizes({
    lg: 12,
    md: 11,
    sm: 10,
    xs: 8,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.overline = {
  ...themeInstance.typography.overline,
  ...responsiveFontSizes({
    lg: 12,
    md: 11,
    sm: 10,
    xs: 8,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
};

themeInstance.typography.button = {
  ...themeInstance.typography.button,
  ...responsiveFontSizes({
    lg: 12,
    md: 11,
    sm: 10,
    xs: 8,
    theme: themeInstance,
  }),
  color: themeInstance.palette.text.primary,
  cursor: "pointer",
};

const theme = { ...themeInstance };

export default theme;
