import useWebSocket, { ReadyState } from "react-use-websocket";
import { useAlert } from "contexts/NotificationProvider";
import { useCallback, useEffect, useState } from "react";

interface ISocketProps {
  url: string;
  historyData?: any[];
  notificationData?: any[];
  infoMessage?: string;
}

export const useSocket = ({ url, historyData, infoMessage }: ISocketProps) => {
  const { sendMessage, lastMessage, readyState } = useWebSocket(url);
  const { showNotification } = useAlert();
  const [history, setHistory] = useState<any[]>([]);
  const [notifications, setNotifications] = useState<any[]>([]);
  useEffect(() => {
    if (historyData) {
      setHistory(historyData);
    }
  }, [historyData]);

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data).data;

      if (JSON.parse(lastMessage.data).type === "work_bench_notification") {
        setHistory((prev: any) => [data, ...prev]);
      }
      if (JSON.parse(lastMessage.data).type === "system_notification") {
        setNotifications((prev: any) => [data, ...prev]);
      }

      showNotification?.(
        infoMessage || `New notification: ${data?.title} (${data?.mda?.name || "--"})`,
        {
          type: "info",
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const sendJsonMessage = useCallback((messageObject) => {
    sendMessage(JSON.stringify(messageObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return {
    sendMessage,
    lastMessage,
    readyState,
    history,
    notifications,
    sendJsonMessage,
    connectionStatus,
  };
};
