//@ts-ignore
import { createRoot } from "react-dom/client";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { NotificationProvider } from "contexts/NotificationProvider";
import { TableRecordsProvider } from "shared/components/Table";
import theme from "./theme";
import App from "./App";
import AppInstanceProvider from "contexts/AppInstanceProvider";
import "index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 600000,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
});

/**
 * The quote after the getElementById is Typescript's non-null assertion
 */

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <TableRecordsProvider>
      <StyledEngineProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <AppInstanceProvider theme={theme}>
            <CssBaseline />
            <NotificationProvider>
              <BrowserRouter>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </BrowserRouter>
            </NotificationProvider>
          </AppInstanceProvider>
        </QueryClientProvider>
      </StyledEngineProvider>
    </TableRecordsProvider>
  </LocalizationProvider>
);

serviceWorkerRegistration.unregister();
