import { useEffect, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/styles";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import { Close } from "@mui/icons-material";
import { fetchNotifications, setReadAll } from "./NotificationServices";
import { useQuery, useQueryClient, useInfiniteQuery } from "react-query";
import Empty from "shared/components/Empty";
import { ReactComponent as CompletedIcon } from "../../../assets/svgs/processCompleted.svg";
import { ReactComponent as ReturnIcon } from "../../../assets/svgs/processApproval.svg";
import { ReactComponent as CancelledIcon } from "../../../assets/svgs/processCancelled.svg";
import { ReactComponent as ActionIcon } from "../../../assets/svgs/processAction.svg";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useNavigate } from "react-router-dom";

const NotificationDrawer = ({ open, close, notifications }: any) => {
  const queryClient = useQueryClient();
  const [notificationData, setNotificationData] = useState<any>([]);
  const [newNotifications, setNewNotifications] = useState<any>([]);
  const loadMoreRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    setNewNotifications(notifications);
  }, [notifications]);

  const getIconAndAction = (phaseStatus: any) => {
    switch (phaseStatus) {
      case "ISSUED":
        return <CompletedIcon />;
      case "REJECTED":
        return <CancelledIcon />;
      case "RETURNED":
        return <ReturnIcon />;
      case "SUCCESSFUL":
        return <ActionIcon />;

      default:
        return <CompletedIcon />;
    }
  };

  const { fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery<any, Error>(
    ["fetching-user-notification", { page: 1, pageSize: 10 }],
    fetchNotifications,
    {
      getNextPageParam: (lastPage: any, pages: any) => {
        const pagesNum = lastPage?.total_pages;
        if (pagesNum > pages?.length) {
          const newPage = pages?.length + 1;
          return newPage;
        }
        return undefined;
      },
      onSuccess: (data: any) => {
        const { pages } = data;
        const notificationList = pages.flatMap((x: any) => x.results);
        setNotificationData(notificationList);
      },
    }
  );

  const { refetch } = useQuery(["setting-read-all"], setReadAll, {
    enabled: false,
    onSuccess: () => {
      queryClient.refetchQueries(`fetching-user-notification`);
      setNewNotifications([]);
    },
  });

  const onReadAll = () => {
    refetch();
  };

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => fetchNextPage(),
  });

  const displayTime = (index: number) => {
    const dateTime = notificationData?.[index]?.created_at;
    const parsedDateTime: any = new Date(dateTime);
    let currentDate: any = new Date();

    const timeDifferenceInMilliseconds = currentDate - parsedDateTime;

    const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
    const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);
    const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24);

    if (timeDifferenceInMinutes === 0) {
      return "now";
    } else if (timeDifferenceInMinutes > 0 && timeDifferenceInMinutes < 60) {
      return `${timeDifferenceInMinutes} ${timeDifferenceInMinutes === 1 ? "min ago" : "mins ago"}`;
    } else if (timeDifferenceInHours > 0 && timeDifferenceInHours < 23) {
      return `${timeDifferenceInHours} ${timeDifferenceInHours === 1 ? "hour ago" : "hours ago"} `;
    } else if (timeDifferenceInDays === 1) {
      return `Yesterday, ${format(new Date(dateTime), "hh:mm aaaa")}`;
    } else if (timeDifferenceInDays > 1) {
      return `${format(new Date(dateTime), "MMMM dd, yyyy hh:mm aaaa")}`;
    }
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={close} sx={{ width: "400px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "400px" }}>
        <Wrapper>
          <Title>
            <Typography variant="subtitle1" color="text.title">
              Notifications
            </Typography>
          </Title>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Wrapper>
        <Container>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Typography variant="caption" color="text.secondary">
              LATEST
            </Typography>
            <Button
              variant="text"
              sx={{ tSize: "10px", color: "#272833", cursor: "pointer" }}
              onClick={onReadAll}
            >
              Mark all as read
            </Button>
          </Box>
          {newNotifications?.length > 0 &&
            newNotifications?.map(
              ({ title, message, read, status_flag, link }: any, index: number) => (
                <Wrap key={message} sx={{ backgroundColor: read ? "#FFFFFF" : "#f0f5ff" }}>
                  <Box sx={{ m: 2 }}>{getIconAndAction(status_flag)}</Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ marginTop: title ? "10px" : "", fontWeight: 700 }}
                    >
                      {" "}
                      {title ? title : ""}{" "}
                    </Typography>
                    <Typography variant="caption" sx={{ marginY: "5px" }}>
                      <span style={spanStyle}>{message}</span>
                    </Typography>
                    <Box display="flex">
                      <Box width="200px">
                        <Typography variant="caption" sx={{ color: "#605E5C" }}>
                          {displayTime(index)}
                        </Typography>
                      </Box>
                      <Button
                        variant="text"
                        onClick={() => {
                          link && navigate(link);
                          close();
                        }}
                        sx={{
                          marginTop: "-7px",
                          color: "primary",
                          cursor: "pointer",
                        }}
                      >
                        View details
                      </Button>
                    </Box>
                  </Box>
                </Wrap>
              )
            )}
          {isLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : notificationData?.length > 0 ? (
            <div style={{ paddingBottom: "20px" }}>
              {notificationData?.map(
                ({ title, message, read, status_flag, link }: any, index: number) => (
                  <Wrap key={message} sx={{ backgroundColor: read ? "#FFFFFF" : "#f0f5ff" }}>
                    <Box sx={{ m: 2 }}>{getIconAndAction(status_flag)}</Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ marginTop: title ? "10px" : "", fontWeight: 700 }}
                      >
                        {" "}
                        {title ? title : ""}{" "}
                      </Typography>
                      <Typography variant="caption" sx={{ marginY: "5px" }}>
                        <span style={spanStyle}>{message}</span>
                      </Typography>
                      <Box display="flex">
                        <Box width="200px">
                          <Typography variant="caption" sx={{ color: "#605E5C" }}>
                            {displayTime(index)}
                          </Typography>
                        </Box>
                        <Button
                          variant="text"
                          onClick={() => {
                            link && navigate(link);
                            close();
                          }}
                          sx={{
                            marginTop: "-7px",
                            color: "primary",
                            cursor: "pointer",
                          }}
                        >
                          View details
                        </Button>
                      </Box>
                    </Box>
                  </Wrap>
                )
              )}
              {/* @ts-ignore */}
              <div ref={loadMoreRef} />
              {isFetchingNextPage && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size="1rem" />
                </Box>
              )}
            </div>
          ) : (
            <>
              <Box mt={64}>
                <Empty title="No Notification" description="You have no notification." />
              </Box>
            </>
          )}
        </Container>
      </Box>
    </Drawer>
  );
};

export default NotificationDrawer;
const Container = styled(Box)({
  paddingLeft: "15px",
  paddingRight: "15px",
  display: "flex",
  flexDirection: "column",
});

const Wrapper = styled(Box)({
  height: "40px",
  display: "flex",
  marginBottom: 4,
});

const Title = styled(Box)({
  height: "100%",
  width: "89%",
  backgroundColor: "#F1F2F6",
  display: "flex",
  alignItems: "center",
  paddingLeft: "15px",
});

const spanStyle = {
  color: "#272833",
  fontWeight: "600",
};

const Wrap = styled(Box)({
  display: "flex",
  marginTop: "10px",
  marginBottom: "10px",
  paddingRight: "10px",
});
