const genericMessage = "An error occrued please contact admin";
// const genericMessage = "Something went wrong while trying to connect with the server";
const unknownError = "An unknown error occured";
const handleApiError = (error: any, fields?: any) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.warn("[Axios Error]", error, error?.response);
  }

  const field_name = error?.response?.data?.field_name;
  const message = error?.response?.data?.message;

  if (!message) {
    return "An unknown error occured";
  }

  if (!error?.response?.status) {
    return error?.message || unknownError;
  }
  switch (error?.response?.status) {
    case 400: {
      return field_name && message
        ? `Field "${field_name}" failed validation: ${message}`
        : "A validation error occured";
    }
    case 401:
      return message || "You are not authenticated";

    case 403:
      return message || "You are not authorized to perform this action";
    case 404:
      return message || "The resource you are trying to load cannot be found";
    case 409:
      return message || "A duplicate already exists";
    case 500:
      return genericMessage;
    default:
      return message || unknownError;
  }
};

export default handleApiError;
