import { IFilesTableDataResponse } from "adminModule/interfaces/fileInterface";
import { ICreatePersonnel } from "../interfaces/personnelInterfact";
import Api from "utils/api";
import queryParamsHelper from "utils/queryParamsHelper";
import { IQueryKey } from "pages/Files/interfaces";

export interface IQueryParams {
  ordering: string;
  page: number;
  pageSize: number;
  search: string;
  status: string;
}

export const fetchPersonnels = async ({ queryKey, status, mda, department }: any) => {
  const [, { ordering, page, pageSize, search }] = queryKey;

  const mdaString = mda ? mda.map((v: string) => `mda=${v}`).join("&") : "";
  const departmentString = department
    ? department.map((v: string) => `department=${v}`).join("&")
    : "";

  return Api.get(
    `/personnels/?page_size=${pageSize}${page ? "&page=" + page : ""}${
      search ? "&search=" + search : ""
    }${status ? "&status=" + status : ""}${mdaString ? `&${mdaString}` : ""}${
      departmentString ? `&${departmentString}` : ""
    }${ordering ? "&ordering=" + ordering : ""}`
  ).then((res) => res.data);
};

export const fetchContractLedgerById = async ({ queryKey }: any) => {
  const [, { id, ordering, page, pageSize, search }] = queryKey;
  return Api.get(
    `/payments/contracts/${id}/items?page=${page}&page_size=${pageSize}${
      search ? "&search=" + search : ""
    }${ordering ? "&ordering=" : ""}`
  ).then((res) => res.data);
};
export const fetchPersonnelsByDepartment = async ({ queryKey }: any) => {
  const [, { id, page, pageSize, ordering }] = queryKey;

  return Api.get(
    `/personnels/?department=${id}&page=${page}&page_size=${pageSize}${
      ordering ? "&ordering=" + ordering : ""
    }`
  ).then((res) => res.data);
};

export const fetchContractDetailsById = async ({ queryKey }: any) => {
  const [, { id }] = queryKey;
  return Api.get(`/payments/contracts/${id}`).then((res) => res.data);
};

export const fetchPersonnelOptions = async ({ querykey }: any) => {
  return Api.get(`/personnels/`).then((res) => res.data);
};

export const fetchPersonnelById = async ({ queryKey }: any) => {
  const [, { id }] = queryKey;
  return Api.get(`/personnels/${id}/`).then((res) => res.data);
};
export const fetchUnitById = async ({ queryKey }: any) => {
  const [, { id }] = queryKey;
  return Api.get(`/accounts/departments/unit/${id}/`).then((res) => res.data);
};

export const fetchDepartments = async ({ queryKey }: any) => {
  const [, { mdaId, page, pageSize }] = queryKey;

  return Api.get(
    `/accounts/departments/?mda=${mdaId}${page ? "&page=" + page : ""}&page_size=${pageSize}`
  ).then((res) => res.data);
};

export const fetchAllDepartments = async ({ queryKey, pageParam }: any) => {
  const [, { pageSize, search, mda }] = queryKey;
  const params = {
    search: search,
    page_size: pageSize,
    page: pageParam ? pageParam : 1,
    mda: mda ? mda : undefined,
  };
  try {
    const { data } = await Api.get(`/accounts/departments/`, {
      params,
    });
    return data;
  } catch (e) {}
};

export const fetchUnits = async ({ queryKey }: any) => {
  const [, { departmentId, page, pageSize, mda }] = queryKey;

  return Api.get(
    `/accounts/departments/${departmentId}/units?page_size=${pageSize}${
      page ? "&page=" + page : ""
    }${mda ? "&mda=" + mda : ""}`
  ).then((res) => res.data);
};

export const createPersonnel = async (data: {
  personnelId?: string;
  payload: ICreatePersonnel;
}) => {
  return data.personnelId
    ? Api.patch(`/personnels/${data.personnelId}/`, data.payload).then((res) => res.data)
    : Api.post(`/personnels/`, data.payload).then((res) => res.data);
};

export const fetchPersonnel = async ({ queryKey }: any) => {
  const [, { userId }] = queryKey;
  return Api.get(`/personnels/${userId}`).then((res) => res.data);
};

export const updateResendTokenPersonnel = async (payload: any) => {
  return Api.post(`/personnels/resend-token/`, payload).then((res) => res.data);
};
export const deleteUserPersonnel = async (userId: string | undefined) => {
  return Api.delete(`/personnels/${userId}`).then((res) => res.data);
};

export const updateDeactivatePersonnel = async (userId: string | undefined) => {
  const payload = {
    action: "DEACTIVATE",
  };
  return Api.post(`/personnels/${userId}/update-status/`, payload).then((res) => res.data);
};

export const updateActivatePersonnel = async (userId: string | undefined) => {
  const payload = {
    action: "ACTIVATE",
  };
  return Api.post(`/personnels/${userId}/update-status/`, payload).then((res) => res.data);
};

export const updatePersonnelImage = async (userId: string, formData: FormData) => {
  const response = await Api.patch(`/personnels/${userId}/`, formData);
  return response.data;
};

export const sendActivityOtp = async (id: string) => {
  try {
    const res = await Api.post("/workbench/requests/generate-activity-otp/", { action: id });
    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const sendOtp = async () => {
  try {
    const res = await Api.get("/signature/generate-otp");
    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const updateSignatureMutate = async (formData: FormData) => {
  return Api.post("/signature/", formData).then((res) => res.data);
};

export const fetchDesignation = async ({ queryKey }: IQueryKey) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);

  const response = await Api.get<IFilesTableDataResponse>(`/designation${queryParams}`);

  return response?.data;
};
export const createDesignation = async (payload: { name: string; mda: string }) => {
  const response = await Api.post<IFilesTableDataResponse>(`/designation${payload}`);

  return response?.data;
};
